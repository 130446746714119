(function () {
    'use strict';

    angular.module('linkApp')
        .run(linkAppRoutesToStyles);

    linkAppRoutesToStyles.$inject = ['PageService', 'routesConstant'];

    function linkAppRoutesToStyles(PageService, routesConstant) {
        PageService.addAflPageViewToStateMap(routesConstant.LINK.MAIN.stateName, PageService.AFLPAGEVIEW.LINK);
    }
})();
